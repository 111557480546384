<template>
  <b-overlay :show="loading">
    <b-form
      v-if="!loading"
      @submit.prevent="save"
    >
      <b-form-group
        label="Payment Type:"
        label-for="paymenttype"
        label-cols="4"
        label-align="right"
      >
        <b-form-select
          v-model="form.type"
          required
          id="paymenttype"
          size="sm"
        >
          <b-form-select-option
            :value="null"
            disabled
          >-- Select Type --</b-form-select-option>
          <b-form-select-option value="Visa">Visa</b-form-select-option>
          <b-form-select-option value="MasterCard">MasterCard</b-form-select-option>
          <b-form-select-option value="Discover">Discover</b-form-select-option>
          <b-form-select-option value="AmEx">AmEx</b-form-select-option>
          <b-form-select-option value="ACH">ACH</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group v-if="form.type !== 'ACH'">
        <b-form-group
          label="Name on Account:"
          label-for="paymentname"
          label-cols="4"
          label-align="right"
        >
          <b-form-input
            v-model="form.name"
            id="paymentname"
            size="sm"
            placeholder="Name on Card"
            required
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Account Number:"
          label-for="paymentnumber"
          label-cols="4"
          label-align="right"
        >
          <b-input-group>
            <b-form-input
              v-model="form.number"
              id="ccnumber"
              size="sm"
              placeholder="Credit Card Number"
              required
              :readonly="+form.id > 0 && !isDecrypted"
            ></b-form-input>
            <b-input-group-append v-if="+form.id > 0 && !isDecrypted">
              <b-button
                variant="danger"
                size="sm"
                @click.prevent="showPasswordModal = true"
              >Decrypt</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="Expiration:"
          label-for="paymentexp"
          label-cols="4"
          label-align="right"
        >
          <b-row no-gutters>
            <b-col
              cols="auto"
              class="form-inline"
            >
              <b-form-select
                v-model="form.expmonth"
                id="paymentexp"
                size="sm"
                required
                class="mr-2"
              >
                <option :value="null">MM</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </b-form-select>
              /
              <b-form-select
                v-model="form.expyear"
                id="ccexpyear"
                size="sm"
                required
                :options="years"
                class="ml-2"
              >
              </b-form-select>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group
          v-if="form.code"
          label="CVC Code:"
          label-for="paymentcode"
          label-cols="4"
          label-align="right"
        >
          <b-form-input
            v-model="form.code"
            id="paymentcode"
            placeholder="CVV security code"
            size="sm"
            readonly
          ></b-form-input>
        </b-form-group>
      </b-form-group>
      <b-form-group v-if="form.type === 'ACH'">
    <b-form-group
      label="Bank Name:"
      label-for="bankname"
      label-cols="4"
      label-align="right"
    >
      <b-form-input
        v-model="form.name"
        id="bankname"
        size="sm"
        placeholder="Bank Name"
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Account Type:"
      label-for="accounttype"
      label-cols="4"
      label-align="right"
    >
      <b-form-select
        v-model="form.account_type"
        id="accounttype"
        size="sm"
        required
      >
        <b-form-select-option :value="null" disabled>-- Select Account Type --</b-form-select-option>
        <b-form-select-option value="Checking">Checking</b-form-select-option>
        <b-form-select-option value="Savings">Savings</b-form-select-option>
      </b-form-select>
    </b-form-group>

    <b-form-group
      label="Account Number:"
      label-for="accountnumber"
      label-cols="4"
      label-align="right"
    >
      <b-input-group>
        <b-form-input
          v-model="form.number"
          id="accountnumber"
          size="sm"
          placeholder="Account Number"
          required
          :readonly="+form.id > 0 && !isDecrypted"
          :state="accountNumberValid"
        ></b-form-input>
        <b-input-group-append v-if="+form.id > 0 && !isDecrypted">
          <b-button
            variant="danger"
            size="sm"
            @click.prevent="showPasswordModal = true"
          >Decrypt</b-button>
        </b-input-group-append>
      </b-input-group>
      <b-form-invalid-feedback>
        Account number must be between 9 and 18 digits.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label="Routing Number:"
      label-for="routingnumber"
      label-cols="4"
      label-align="right"
    >
      <b-form-input
        v-model="form.routing_number"
        id="routingnumber"
        size="sm"
        placeholder="Routing Number"
        required
        :state="routingNumberValid || form.routing_number === ''"
      ></b-form-input>
      <b-form-invalid-feedback>
        Routing number must be 9 digits.
      </b-form-invalid-feedback>
    </b-form-group>
  </b-form-group>
      <b-row
        align-h="center"
        align="right"
        class="modal-footer"
      >
        <b-col
          v-if="+id"
          class="text-left"
        >
          <b-button
            @click="confirmDelete"
            variant="danger"
          >Delete</b-button>
        </b-col>
        <b-col>
          <b-button
            type="button"
            @click="$emit('close')"
            variant="secondary"
            class="ml-1"
          >
            <b-icon icon="x" />
            Cancel
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            class="ml-1"
            :disabled="!isFormValid"
          >
            <b-icon icon="check" />
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <b-modal
      v-model="showDelete"
      size="md"
      title="Delete Billing Info"
      button-size="sm"
      ok-variant="danger"
      ok-title="Delete"
      v-if="+id"
      @ok="deleteRecord"
      no-close-on-backdrop
      no-close-on-esc
    >
      <p class="text-center">Are you sure you want to delete this payment information?</p>
    </b-modal>

    <b-modal
      v-model="showPasswordModal"
      size="md"
      title="Decrypt Billing Info"
      button-size="sm"
      ok-variant="danger"
      ok-title="Decrypt"
      :ok-disabled="!decryptPassword"
      @ok="load"
      no-close-on-backdrop
      no-close-on-esc
    >
      <p class="text-center">Enter the password to decrypt the information</p>
      <b-form-input
        v-model="decryptPassword"
        type="password"
        required
        autofocus
      ></b-form-input>
    </b-modal>
  </b-overlay>
</template>
<script>
import CompanyService from '@/services/CompanyService'

export default {
  name: 'PaymentEdit',
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    id: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      loading: false,
      showDelete: false,
      isDecrypted: false,
      decryptPassword: null,
      showPasswordModal: false,
      form: {
        id: null,
        company_id: null,
        type: null,
        name: null,
        number: null,
        code: null,
        expmonth: null,
        expyear: null,
        account_type: null,
        routing_number: null
      },
      record: null,
      years: [{ value: null, text: 'YYYY' }]
    }
  },

  mounted () {
    const d = new Date()
    let year = +d.getFullYear() - 2
    while (year <= +d.getFullYear() + 20) {
      this.years.push({ value: +year, text: year, disabled: (year < +d.getFullYear()) })
      year++
    }
    this.form.company_id = +this.companyId
    this.load()
  },

  computed: {
    accountNumberValid () {
      const requiresDecryption = +this.form.id > 0
      console.log(requiresDecryption)
      if (requiresDecryption && !this.isDecrypted) {
        return true
      }
      return /^\d{9,18}$/.test(this.form.number)
    },
    routingNumberValid () {
      return /^\d{9}$/.test(this.form.routing_number)
    },
    isFormValid () {
      const isPaymentTypeValid = this.form.type !== null
      return this.accountNumberValid && this.routingNumberValid && isPaymentTypeValid
    }
  },

  methods: {
    load () {
      if (this.loading || !+this.id) {
        return false
      }
      this.loading = true
      console.log('Loading...')
      CompanyService.decryptBilling(this.id, this.decryptPassword).then((response) => {
        if (response.data.result) {
          this.record = response.data.info
          this.form.id = +this.record.id
          this.form.company_id = +this.record.company_id
          this.form.type = this.record.type
          this.form.name = this.record.name
          this.form.number = this.record.number
          this.form.routing_number = this.record.routing_number
          this.form.account_type = this.record.account_type
          this.form.expyear = this.record.expires ? parseInt(this.record.expires.substr(0, 4)) : null
          this.form.expmonth = this.record.expires ? this.record.expires.substr(5, 2) : null

          if ('decrypted' in this.record) {
            this.form.number = this.record.decrypted.number
            this.isDecrypted = true
          }
        } else {
          this.record = null
        }
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    save () {
      if (this.loading) {
        return false
      }
      this.loading = true
      CompanyService.saveBilling(this.form).then((response) => {
        this.$emit('refresh')
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    confirmDelete () {
      this.showDelete = true
    },

    deleteRecord () {
      if (this.loading) {
        return false
      }
      this.loading = true
      CompanyService.deleteBilling(this.id).then((response) => {
        this.$emit('refresh')
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    }
  },

  watch: {
    id: function (n) {
      if (+n && (!this.record || +this.record.id !== +n)) {
        this.load()
      }
    }
  }
}
</script>
