<template>
  <b-overlay :show="loading">
    <b-table
      id="billingTable"
      :fields="headers"
      :items="billing"
      bordered
      sticky-header="300px"
      class="small"
      show-empty
      primary-key="id"
      tbody-tr-class="align-middle"
      striped
    >
      <template #cell(number)="data">
        <strong>x{{ data.item.number }}</strong>
        <div v-if="data.item.routing_number">
          <br>
          <strong>Routing Number: </strong>{{ data.item.routing_number }}
        </div>
      </template>
      <template #cell(id)="data">
        <b-button
          variant="warning"
          @click.prevent="edit(data.item.id)"
          title="Edit"
          size="sm"
          :disabled="loading || showModal"
        >
          <b-icon-pencil-fill></b-icon-pencil-fill>
        </b-button>
      </template>
    </b-table>

    <b-button
      variant="danger"
      @click.prevent="edit()"
    >Add New Payment Method</b-button>

    <b-modal
      id="editPaymentModal"
      v-model="showModal"
      hide-footer
      size="lg"
      body-class="pb-0"
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-title>{{ (+selectedId) ? 'Edit Billing ID ' + selectedId : 'Add Payment Information' }}</template>
      <PaymentEdit
        :id="+selectedId"
        :company-id="+companyId"
        @close="$bvModal.hide('editPaymentModal');"
        @refresh="$bvModal.hide('editPaymentModal'); load()"
      ></PaymentEdit>
    </b-modal>
  </b-overlay>
</template>
<script>
import CompanyService from '@/services/CompanyService'
import PaymentEdit from '@/components/admin/company/PaymentEdit'

export default {
  name: 'PaymentInfo',
  components: { PaymentEdit },
  props: {
    companyId: Number
  },

  data () {
    return {
      loading: false,
      selectedId: null,
      showModal: false,
      billing: [],

      headers: [
        {
          key: 'type',
          label: 'Payment Type',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'name',
          label: 'Name on Account',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'number',
          label: 'Account Number',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'expires',
          label: 'Expiration Date',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'id',
          label: '',
          sortable: false,
          tdClass: 'align-middle text-center'
        }
      ]
    }
  },

  mounted () {
    this.load()
  },

  methods: {
    load () {
      if (this.loading || !this.companyId) {
        return false
      }
      this.loading = true
      CompanyService.getBilling(this.companyId).then((response) => {
        this.billing = response.data.info
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    edit (id) {
      if (this.loading) {
        return false
      }
      this.selectedId = (+id) ? +id : null
      this.$nextTick(() => {
        this.showModal = true
      })
    }
  }
}
</script>
