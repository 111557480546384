<template>
  <b-container
    fluid
    style="text-align: left;"
    class="text--black"
  >
    <b-row>
      <b-col
        cols="12"
        md="4"
        class="mb-4"
      >
        <div class="text-left py-1 d-inline">
          <back-button fallback="/admin/company"></back-button>
        </div>
        <div class="h2 d-inline ml-4 text--white">{{ name }}</div>
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="mb-4"
      >
        <b-button
          @click="deleteComp()"
          :variant="active == 1 ? 'danger' : 'info'"
          block
        >
          <b-icon :icon="active == 1 ? 'x-circle' : 'arrow-clockwise'"></b-icon>
          {{ active == 1 ? 'Disable' : 'Enable' }}
        </b-button>
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="mb-4"
      >
        <EditCompany
          @refresh="init()"
          :compId="+id"
          edit
        ></EditCompany>
      </b-col>
    </b-row>

    <UserList
      v-if="organization_id > 0"
      :organizationId="organization_id"
      :companyId="+id"
      :disabled="false"
    >
      <div class="d-inline h4">Users</div>
    </UserList>

    <!-- <b-card class="mb-4 card-border text--black q-table-container">
      <template #header>
        <div class="d-inline h4">Linked Plaid Accounts</div>
      </template>
      <PlaidMap :company-id="+id"></PlaidMap>
    </b-card> -->

    <b-card class="mb-4 card-border text--black q-table-container">
      <template #header>
        <div class="d-inline h4">Payment Information</div>
      </template>
      <PaymentInfo :company-id="+id"></PaymentInfo>
    </b-card>

    <b-card class="mb-4 card-border text--black q-table-container">
      <template #header>
        <div class="d-inline h4">Quickbooks</div>
      </template>
      <b-row>
        <b-col
          cols="12"
          v-if="qbdata && qbdata.qbpath && !editPath"
        >
          <b>Path:</b> {{ qbdata.qbpath }}
          <b-button class="float-right" variant="danger" @click="deleteQBConnection">Delete Connection</b-button>
        </b-col>
        <b-col
          cols="12"
          md="9"
          v-else
        >
          No company file connected!
          <!-- <b-form-input
            v-model="qbdata.qbpath"
            :state="badPath"
            @focus="badPath = null"
            disabled readonly
          ></b-form-input> -->
        </b-col>
        <!--
        <b-col
          cols="12"
          md="3"
          v-if="qbdata && qbdata.qbpath && !editPath"
        >
          <b-button
            @click="editPath = true"
            block
          >
            Update
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="3"
          v-else
        >
          <b-button
            @click="updateQBData()"
            variant="success"
            block
          >
            Save
          </b-button>
        </b-col> -->
      </b-row>
    </b-card>
  </b-container>
</template>
<script>
import CompanyService from '@/services/CompanyService'
import EditCompany from '@/components/admin/CompanyModal'
// import PlaidMap from '@/components/bookkeeping/PlaidMap'
import PaymentInfo from '@/components/admin/company/PaymentInfo'
import BackButton from '@/components/BackButton'
import UserList from '@/components/admin/user/List'

export default {
  name: 'CompanyAdmin',

  data () {
    return {
      name: null,
      organization_id: 0,
      organization_name: null,
      qbdata: {
        qbpath: null
      },
      editPath: false,
      banks: [],
      users: [],
      badPath: null,
      active: 1
    }
  },

  components: {
    EditCompany,
    // PlaidMap,
    PaymentInfo,
    BackButton,
    UserList
  },

  props: {
    id: {
      type: [Number, String],
      default: 0
    }
  },

  created () {
    this.init()
  },

  methods: {
    init () {
      this.$updateloading(1)
      CompanyService.getCompany(this.id).then(
        (response) => {
          this.name = response.data.info.company.name
          this.organization_id = response.data.info.company.organization_id
          this.organization_name = response.data.info.company.organization_name
          this.users = response.data.info.users
          if (response.data.info.banks) {
            this.banks = response.data.info.banks
          }
          if (response.data.info.qbdata) {
            this.qbdata = response.data.info.qbdata
          } else {
            this.qbdata = { qbpath: null }
          }

          this.active = response.data.info.company.active
        }
      ).finally(() => { this.$updateloading(-1) })
    },

    deleteComp () {
      this.$bvModal.msgBoxConfirm('Are you sure?').then(
        val => {
          if (val) {
            CompanyService.deleteCompany(this.id).then(
              (response) => {
                this.$aimNotify.notify(response)
                this.init()
              }
            )
          }
        }
      )
    },

    deleteQBConnection () {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this connection? If you do you will have to reconnect through the connector program.', {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'DELETE'
      }).then(
        (value) => {
          if (value) {
            CompanyService.deleteQBData(this.id).then(
              (response) => {
                this.$aimNotify.notify(response)
              }
            ).finally(() => { this.init() })
          }
        }
      )
    },

    updateQBData () {
      this.editPath = false
      this.badPath = null
      const data = {
        qbdata: this.qbdata,
        companyId: this.id
      }
      CompanyService.updateQBData(data).then(
        (response) => {
          this.$aimNotify.notify(response)
          this.init()
        }
      ).catch(error => {
        this.$aimNotify.error(error.response)
        this.init()
        this.badPath = false
        this.editPath = true
      })
    }
  }
}
</script>
